import React, { useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Autoplay } from "swiper";
import { Pagination } from "swiper";

import RacingImg from "../../../Assets/racing_new.webp";
import ActionImg from "../../../Assets/action_new.webp";
import ArcadeImg from "../../../Assets/arcade_new.webp";
import CasualImg from "../../../Assets/casual_new.webp";
import CPLImg from "../../../Assets/sports_new.webp";
import ShootingImg from "../../../Assets/shooting_new.webp";
import PuzzleImg from "../../../Assets/puzzle_new.webp";
import EducationImg from "../../../Assets/educational_new.webp";

import "./PageFour.less";
import { Col, Row } from "antd";

import { useWindowSize } from "../../Commons/Common";

const data = [
  {
    key: "1",
    image: ActionImg,
    heading: "ACTION",
  },
  {
    key: "2",
    image: ArcadeImg,
    heading: "ARCADE",
  },
  {
    key: "3",
    image: CasualImg,
    heading: "CASUAL",
  },
  {
    key: "4",
    image: CPLImg,
    heading: "SPORTS",
  },
  {
    key: "5",
    image: EducationImg,
    heading: "EDUCATION",
  },
  {
    key: "6",
    image: ShootingImg,
    heading: "SHOOTING",
  },
  {
    key: "7",
    image: RacingImg,
    heading: "RACING",
  },
  {
    key: "8",
    image: PuzzleImg,
    heading: "PUZZLE",
  },
];

const frData = [
  {
    key: "1",
    image: ActionImg,
    heading: "ACTION",
  },
  {
    key: "2",
    image: ArcadeImg,
    heading: "ARCADE",
  },
  {
    key: "3",
    image: CasualImg,
    heading: "CASUAL",
  },
  {
    key: "4",
    image: CPLImg,
    heading: "SPORTS",
  },
];

const srData = [
  {
    key: "1",
    image: EducationImg,
    heading: "EDUCATION",
  },
  {
    key: "2",
    image: ShootingImg,
    heading: "SHOOTING",
  },
  {
    key: "3",
    image: RacingImg,
    heading: "RACING",
  },
  {
    key: "4",
    image: PuzzleImg,
    heading: "PUZZLE",
  },
];

const PageFour = () => {
  const [width, height] = useWindowSize();

  return (
    <div className="aPageFour_bg">
      {/* <div className=""> */}
      <Row
        justify="space-evenly"
        // gutter={[
        //   width > 1200 ? 80 : width > 992 ? 40 : width > 768 ? 40 : 30,
        //   width > 1200 ? 80 : width > 992 ? 40 : width > 768 ? 40 : 30,
        // ]}
        // data-aos="fade-up"
        className="aPageFour_rHide"
      >
        {frData &&
          frData.map((item) => {
            return (
              <>
                <Col key={`${item.key}`} align="center" data-aos="zoom-in">
                  <div className="aPageFour_card">
                    <div className="aPageFour_card_aOuter">
                      <div className="aPageFour_card_aInner">
                        <img
                          src={item.image}
                          alt="ActionImgBtn"
                          className="aPageFour_card_img"
                        />
                      </div>
                      <span className="aPageFour_card_aDots" />
                    </div>

                    <div className="aPageFour_card_content">{item.heading}</div>
                  </div>
                </Col>
              </>
            );
          })}
      </Row>
      <Row
        justify="space-evenly"
        // gutter={[
        //   width > 1200 ? 80 : width > 992 ? 40 : width > 768 ? 40 : 30,
        //   width > 1200 ? 80 : width > 992 ? 40 : width > 768 ? 40 : 30,
        // ]}
        style={{
          marginTop:
            width > 1200 ? 30 : width > 992 ? 20 : width > 768 ? 40 : 10,
        }}
        // data-aos="fade-up"
        className="aPageFour_rHide"
      >
        {srData &&
          srData.map((item) => {
            return (
              <>
                <Col key={`${item.key}`} data-aos="zoom-in">
                  <div className="aPageFour_card">
                    <div className="aPageFour_card_aOuter">
                      <div className="aPageFour_card_aInner">
                        <img
                          src={item.image}
                          alt="ActionImgBtn"
                          className="aPageFour_card_img"
                        />
                      </div>
                      <span className="aPageFour_card_aDots" />
                    </div>

                    <div className="aPageFour_card_content">{item.heading}</div>
                  </div>
                </Col>
              </>
            );
          })}
      </Row>

      <Swiper
        // data-aos="fade-up"
        slidesPerView={2}
        // spaceBetween={0}
        slidesPerGroup={2}
        loop={true}
        autoplay={{
          delay: 1500,
          disableOnInteraction: false,
          pauseOnMouseEnter: true,
        }}
        loopFillGroupWithBlank={true}
        modules={[Autoplay, Pagination]}
        pagination={{
          // enabled: false,
          clickable: true,
          bulletClass: `swiper-pagination-bullet`,
          bulletActiveClass: "swiper-pagination-bullet-active",
        }}
        className="aPageFour_mySwiper"
      // style={{
      //   width: "100%",
      // }}
      // centeredSlides={true}
      >
        {data &&
          data.map((item) => {
            return (
              <SwiperSlide>
                <div className="aPageFour_SwiperContainer">
                  <div className="aPageFour_card">
                    <div className="aPageFour_card_aOuter">
                      <div className="aPageFour_card_aInner">
                        <img
                          src={item.image}
                          alt="ActionImgBtn"
                          className="aPageFour_card_img"
                        />
                      </div>
                      <span className="aPageFour_card_aDots" />
                    </div>

                    <div className="aPageFour_card_content">{item.heading}</div>
                  </div>
                </div>
              </SwiperSlide>
            );
          })}
      </Swiper>
      {/* </div> */}
    </div>
  );
};

export default PageFour;
