import React from "react";
import "./PageOne.less";
// import whiteMonoster from "../../../Assets/banner 2-min.webp";
import DesktopAnimation from "../../../Assets/Animation/Desktop Animation.webm";
//import MobileAnimation from "../../../Assets/Animation/Mobile Animation.mp4";
import MobileAnimationCom from "../../../Assets/Animation/Mobile AnimationCom.mp4";

import { Col, Row } from "antd";


const PageOne = () => {

  return (
    <div className="PageOneDiv" style={{ overflow: "hidden" }}>
      {/* <img src={whiteMonoster} alt="" height="100%" width="100%"/> */}

      <video className="DesktopAnimationClass" loop
        style={{
          // marginTop: "-1%"
        }}
        width={"100%"} height={"100%"} autoPlay muted preload = "true">
        {/* <source src="Presentation1.webm" type="video/mp4" ></source> */}
        {/* <source src="Presentation1.ogg" type="video/ogg" ></source> */}
        <source src={DesktopAnimation} type="video/mp4" ></source>
      </video>
      <video className="MobileAnimationClass" loop
        style={{
          // marginTop: "-1%"
        }}
        width={"100%"} height={"100%"} autoPlay muted playsInline preload = "auto">
        {/* <source src="Presentation1.webm" type="video/mp4" ></source> */}
        {/* <source src="Presentation1.ogg" type="video/ogg" ></source> */}
        <source src={MobileAnimationCom} type="video/mp4" ></source>
      </video>

      {/* <Row>
        <Col className="pageOne_monoster">
          <img
            src={whiteMonoster}
            alt="monosterBtn"
            style={{ width: 90, height: 80 }}
          />
        </Col>
      </Row>
      <div style={{ height: 440 }}>
        <Row style={{ padding: "140px 30px 0 30px" }} justify="center">
          <Col className="Page1_Multiplayer_Games">MULTIPLAYER/PVP GAMES</Col>
        </Row>


        <Row
          justify="space-between"
          style={{
            padding: "0 30px",
            zIndex: 100,
          }}
          className="pageOne_box_center"
        >
          <Col>
            <div className="Page1_HTML_Games">100+ HTML5 GAMES</div>
          </Col>
          <Col>
            <div className="Page1_Localized_Games">LOCALIZED GAMES</div>
          </Col>
        </Row>
      </div> */}
    </div>
  );
};

export default PageOne;
