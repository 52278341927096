import React from "react";
import "./PageThree.less";
import officeBoy from "../../../Assets/officeguy.webp";
import { useNavigate } from "react-router-dom";

import { Col, Row, Carousel } from "antd";

const PageThree = () => {
  let navigate = useNavigate();
  return (
    <div className="pageThree_bg_Home">
      <div>
        <div className="page_three_ourservices" style={{ textAlign: "center" }}>
          OUR SERVICES
        </div>
        <div
          className="hbarOuter"
          style={{
            padding: "0px 40px",
            justifyContent: "center",
            align_items: "center",
            display: "flex",
            marginTop: 5,
          }}
        >
          <div className="pageThree_hBar">
            <div className="page_three_hEl" />
          </div>
        </div>
      </div>
      <div className="outerDiv">
        <div className="ourService_char_card">
          <img
            src={officeBoy}
            alt="imgBtn"
            style={{ width: "120%", height: "120%" }}
          />
        </div>
        <div className="allCardsDiv">
          <div className="pageThree_cCard">
            <div className="pageThree_cCardHeading_One">
              Licensing<span style={{ color: "#185563" }}>&nbsp;of Games</span>
            </div>
            <div
              className="pageThree_cCardText"
              style={{ padding: "8px 20px 30px 20px", lineHeight: "1.5" }}
            >
              We offer our clients the option to get a non-exclusive license for
              our games for a fixed sum. The budget-friendly licensing allows
              you to integrate addictive HTML5 games on your platform and
              generate optimum revenue{" "}
              <span
                style={{
                  color: "#21ce86",
                  textDecoration: "underline",
                  fontWeight: "bold",
                  cursor: "pointer",
                }}
                onClick={() => {
                  navigate("/license-html5-games");
                  window.scrollTo(0, 0);
                }}
              >
                Read More
              </span>
            </div>
          </div>
          <div className="pageThree_cCard">
            <div className="pageThree_cCardHeading_One">
              Revenue<span style={{ color: "#185563" }}>&nbsp;Share</span>
            </div>
            <div
              className="pageThree_cCardText"
              style={{ padding: "8px 20px 30px 20px", lineHeight: "1.5" }}
            >
              For brands on tighter budgets, we offer our 100+ HTML5 games
              embedded with ads on a revenue-sharing basis. With Revenue Share,
              brands can gain access to our premium HTML5 games and can earn a
              good percentage of the total revenue{" "}
              <span
                style={{
                  color: "#21ce86",
                  textDecoration: "underline",
                  fontWeight: "bold",
                  cursor: "pointer",
                }}
                onClick={() => {
                  navigate("/casual-gaming-solution");
                  window.scrollTo(0, 0);
                }}
              >
                Read More
              </span>
            </div>
          </div>
          <div className="pageThree_cCard">
            <div className="pageThree_cCardHeading_One">
              Localization
              <span style={{ color: "#185563" }}>&nbsp;of Games</span>
            </div>
            <div
              className="pageThree_cCardText"
              style={{ padding: "8px 20px 30px 20px", lineHeight: "1.5" }}
            >
              We customize our games in multiple languages to eliminate
              linguistic barriers and regional issues. We can make games
              available in regional languages so that players in different
              regions can enjoy games in their native languages as well.
            </div>
          </div>
          <div className="pageThree_cCard">
            <div className="pageThree_cCardHeading_One">
              H5<span style={{ color: "#185563" }}>&nbsp;Game Development</span>
            </div>
            <div
              className="pageThree_cCardText"
              style={{ padding: "8px 20px 30px 20px", lineHeight: "1.5" }}
            >
              Being highly skilled at HTML5 game development, our developers can
              undertake challenging projects and build stunning games for mobile
              and web platforms. We can develop well-optimized HTML5 games with
              engaging themes and unique storylines.
            </div>
          </div>
          <div className="pageThree_cCard">
            <div className="pageThree_cCardHeading_One">
              Re-Skin of<span style={{ color: "#185563" }}>&nbsp;Games</span>
            </div>
            <div
              className="pageThree_cCardText"
              style={{ padding: "8px 20px 30px 20px", lineHeight: "1.5" }}
            >
              Based on the requirements, we provide re-skinned variants of our
              games to perfectly match your brand without altering the core
              mechanics of the games. Our re-skinned games give you a unique way
              to promote your brand effectively.
            </div>
          </div>
          <div className="pageThree_cCard">
            <div className="pageThree_cCardHeading_One">
              SDK<span style={{ color: "#185563" }}>&nbsp;Integration</span>
            </div>
            <div
              className="pageThree_cCardText"
              style={{ padding: "8px 20px 30px 20px", lineHeight: "1.5" }}
            >
              Based on client needs, we leverage certain functions from the
              client’s SDK that allows us to embed clients’ ads into our HTML5
              games. With the help of SDK Integration, we can also provide games
              along with insights like gameplay duration, player scores, etc.
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PageThree;
