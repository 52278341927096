import React from "react";

import PageNine from "./PageNine.jsx";
import IconsAnimation from "../../Commons/IConsAnimation";

import "./PageEight.less";

import xBrand from "../../../Assets/x-branding-icon.webp";

import partner1_Img from "../../../Assets/Partners/Crazy-games 2.webp";
import partner2_Img from "../../../Assets/Partners/Games-distribution 2.webp";
import partner3_Img from "../../../Assets/Partners/Jagran-media.webp";
import partner4_Img from "../../../Assets/Partners/jio-games 2.webp";
import partner5_Img from "../../../Assets/Partners/mx-player.webp";

import { useWindowSize } from "../../Commons/Common";

const PageEight = () => {

  const [width, height] = useWindowSize();

  const partnerItem = [
    { partner_Img: partner1_Img ,gameLink:"https://www.crazygames.com/game/cricket-world-cup"},
    { partner_Img: partner2_Img },
    { partner_Img: partner3_Img },
    { partner_Img: partner4_Img },
    { partner_Img: partner5_Img },
  ]

  return (
    <div className="pageEight_bg" style={{
      overflow: "hidden"
    }}>
      <div className="pageEight_xBrand">
        <img src={xBrand} alt="" height={"120px"} width={"150px"} />
      </div>
      <div className="pageEight_xBrand2">
        <img src={xBrand} alt="" height={"90px"} width={"100px"} />
      </div>
      <div className="pageEight_text">OUR GAMES LIVE ON </div>
      <div className="pageEight_hBar_wrapper">
        <div className="pageEight_hBar">
          <div className="page_eight_hEl" />
        </div>
      </div>
      {/* <IconsAnimation /> */}
      <div className="pageEightPartners">
        {partnerItem &&
          partnerItem.map((partner, index) => {
            if (index == 2 && width <= 576) { }
            else {
              return (
                <div key={index}>
                  <a href={partner.gameLink} target="_blank">
                    <div className="pageEight_BodyCardChild">
                      <img
                        className="bodyCardImage"
                        src={partner.partner_Img}
                        alt="Partner Img"
                        width={width <= 576 ? 130 : 200}
                      />
                    </div>
                  </a>
                </div>
              );
            };
          })}
      </div>
    </div>
  );
};

export default PageEight;
