import React from "react";

import RacingImg from "../../../Assets/racing_new.webp";
import ActionImg from "../../../Assets/action_new.webp";
import ArcadeImg from "../../../Assets/arcade_new.webp";
import CasualImg from "../../../Assets/casual_new.webp";
import CPLImg from "../../../Assets/sports_new.webp";
import ShootingImg from "../../../Assets/shooting_new.webp";
import PuzzleImg from "../../../Assets/puzzle_new.webp";
import EducationImg from "../../../Assets/educational_new.webp";

import xBrand from "../../../Assets/x-branding-icon.webp";

import "./PageFour.less";
import { Col, Row } from "antd";

const PageFour = () => {
  return (
    <div style={{ padding: 24 }} className="pageFourBg">
      <div className="pageFour_xBrand">
        <img src={xBrand} alt="" height={"120px"} width={"150px"} />
      </div>
      <div className="pageFour_xBrand2">
        <img src={xBrand} alt="" height={"90px"} width={"100px"} />
      </div>
      <div className="page_four_games_genre" style={{ textAlign: "center" }}>
        GAMES GENRE
      </div>

      <div className="pageFour_hBar_wrapper" style={{ }}>
        <div className="pageFour_hBar">
          <div className="page_four_hEl" />
        </div>
      </div>

      <Row justify="center" gutter={[30, 30]}>{/* ACTION */}
        <Col>
          <div className="flip-card">
            <div className="flip-card-inner">
              <div className="Apage_four_card">
                <div className="Apage_four_card_image">
                  <img src={ActionImg} alt="ActionImgBtn" className="card_img" />
                </div>
                <div className="Apage_four_card_content">ACTION</div>
              </div>
              <div className="Apage_four_card_after_hover">
                We have mastered the art of HTML5 game development, and our
                development team is skilled in developing action-packed gameplay,
                where each thoughtfully designed level will bring a new challenge
                for users, leading to a more enjoyable gameplay session.
              </div>
            </div>
          </div>
        </Col>
        {/* ARCADE */}
        <Col>
          <div className="flip-card">
            <div className="flip-card-inner">
              <div className="Apage_four_card">
                <div className="Apage_four_card_image">
                  <img src={ArcadeImg} alt="ArcadeImgBtn" className="card_img" />
                </div>
                <div className="Apage_four_card_content">ARCADE</div>
              </div>
              <div className="Apage_four_card_after_hover">
                Our game developers are highly talented in creating some of the
                best and high-quality collections of lightweight and fun arcade
                games that are perfect for your users who visit your portal in
                search of short bursts of gameplay.
              </div>
            </div>
          </div>
        </Col>
        {/* CASUAL */}
        <Col>
          <div className="flip-card">
            <div className="flip-card-inner">
              <div className="Apage_four_card">
                <div className="Apage_four_card_image">
                  <img src={CasualImg} alt="CasualImgBtn" className="card_img" />
                </div>
                <div className="Apage_four_card_content">CASUAL</div>
              </div>
              <div className="Apage_four_card_after_hover">
                We develop simple and easy-to-understand casual games that allow
                users to engage in the games spontaneously. The casual games we
                develop and provide our clients can be easily enjoyed by the
                newbies who have less gaming skills.
              </div>
            </div>
          </div>
        </Col>
        {/* SPORTS */}
        <Col>
          <div className="flip-card">
            <div className="flip-card-inner">
              <div className="Apage_four_card">
                <div className="Apage_four_card_image">
                  <img src={CPLImg} alt="CPLImgBtn" className="card_img" />
                </div>
                <div className="Apage_four_card_content">SPORTS</div>
              </div>
              <div className="Apage_four_card_after_hover">
                As a leading provider of HTML5 games, we offer you access to a
                wide variety of trending sports games to attract more traffic to
                your platform. We constantly keep updating our sports game library
                to enable you to deliver immersive experiences of the sports
                games.
              </div>
            </div>
          </div>
        </Col>
      </Row>
      <Row justify="center" gutter={[30, 30]} style={{ marginTop: 30 }}>

        {/* Shooting */}
        <Col>
          <div className="flip-card">
            <div className="flip-card-inner">
              <div className="Apage_four_card">
                <div className="Apage_four_card_image">
                  <img
                    src={ShootingImg}
                    alt="ShootingImgBtn"
                    className="card_img"
                  />
                </div>
                <div className="Apage_four_card_content">SHOOTING</div>
              </div>
              <div className="Apage_four_card_after_hover">
                Shooting games have always been immensely popular among users. Our
                efficient HTML5 game development team has expertise in building
                amazing shooting games to take the entertainment level of your
                users a notch higher.
              </div>
            </div>
          </div>
        </Col>
        {/* Education */}
        <Col>
          <div className="flip-card">
            <div className="flip-card-inner">
              <div className="Apage_four_card">
                <div className="Apage_four_card_image">
                  <img
                    src={EducationImg}
                    alt="EducationImgBtn"
                    className="card_img"
                  />
                </div>
                <div className="Apage_four_card_content">EDUCATION</div>
              </div>
              <div className="Apage_four_card_after_hover">
                By using the best-in-class technology stacks, our HTML5 games can
                make learning fun for your users. Our game developers use engaging
                and interactive design paired with highly responsive code to
                deliver a smoother learning experience through our games.
              </div>
            </div>
          </div>
        </Col>
        {/* Racing */}
        <Col>
          <div className="flip-card">
            <div className="flip-card-inner">
              <div className="Apage_four_card">
                <div className="Apage_four_card_image">
                  <img src={RacingImg} alt="RacingImgBtn" className="card_img" />
                </div>
                <div className="Apage_four_card_content">RACING</div>
              </div>
              <div className="Apage_four_card_after_hover">
                Racing games are perfect to give your users an adrenaline rush
                while chasing opponents. Our team is well-versed with the
                high-speed nature of these games and the techniques needed to
                guarantee a smooth gaming experience for your users.
              </div>
            </div>
          </div>
        </Col>
        {/* Puzzle */}
        <Col>
          <div className="flip-card">
            <div className="flip-card-inner">
              <div className="Apage_four_card">
                <div className="Apage_four_card_image">
                  <img src={PuzzleImg} alt="PuzzleImgBtn" className="card_img" />
                </div>
                <div className="Apage_four_card_content">PUZZLE</div>
              </div>
              <div className="Apage_four_card_after_hover">
                Our team design and develop interesting puzzle games that involve
                solving mysteries with the use of a lot of brain and logic. Our
                puzzle-based games are perfect for giving your audience the option
                to enhance their puzzle-solving, reasoning, and analysis skills.
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default PageFour;
