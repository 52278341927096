import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import "./PageTwo.less";
import { Col, Row, Button } from "antd";


// Last 7
import banner97 from "../../../Assets/Banner/quiz-x (1).webp";
import banner96 from "../../../Assets/Banner/sudoku_x.webp";
import banner95 from "../../../Assets/Banner/Onu Live.png";
import banner94 from "../../../Assets/Banner/Carrom Live.webp";
import banner93 from "../../../Assets/Banner/football stars foreign.png";
import banner92 from "../../../Assets/Banner/guardians of gold.webp";
import banner91 from "../../../Assets/Banner/captain-gold.webp";
import banner90 from "../../../Assets/Banner/The Dandy.webp";
import banner89 from "../../../Assets/Banner/cards 21.webp";
import banner88 from "../../../Assets/Banner/fruit chef.webp";
import banner87 from "../../../Assets/Banner/cricket live.webp";
import banner86 from "../../../Assets/Banner/droid o.webp";



// Rest all games
import banner85 from "../../../Assets/Banner/robo clone.webp";
import banner84 from "../../../Assets/Banner/ram the yoddha.webp";
import banner83 from "../../../Assets/Banner/birds vs blocks.webp";
import banner82 from "../../../Assets/Banner/basket champ.webp";
import banner81 from "../../../Assets/Banner/aqua blocks.webp";
import banner80 from "../../../Assets/Banner/cpl 2024.webp";
import banner79 from "../../../Assets/Banner/krishna jump.webp";
import banner78 from "../../../Assets/Banner/jungle bricks.webp";
import banner77 from "../../../Assets/Banner/cricket 2024.webp";
import banner76 from "../../../Assets/Banner/street cricket.webp";
import banner75 from "../../../Assets/Banner/thankyou santa.webp";
import banner74 from "../../../Assets/Banner/halloween night.webp";
import banner73 from "../../../Assets/Banner/feed bobo.webp";
import banner72 from "../../../Assets/Banner/cricket world cup.webp";
import banner71 from "../../../Assets/Banner/guns and bottles.webp";
import banner70 from "../../../Assets/Banner/run panda run.webp";
import banner69 from "../../../Assets/Banner/piggy night.webp";
import banner68 from "../../../Assets/Banner/pingu and friends.webp";
import banner67 from "../../../Assets/Banner/santa or thief.webp";
import banner66 from "../../../Assets/Banner/santa ski.webp";
import banner65 from "../../../Assets/Banner/crazy balls.webp";
import banner64 from "../../../Assets/Banner/hit the glow.webp";
import banner63 from "../../../Assets/Banner/jumpy kangaroo.webp";
import banner62 from "../../../Assets/Banner/halloween bingo.webp";
import banner61 from "../../../Assets/Banner/caveman adventure.webp";
import banner60 from "../../../Assets/Banner/fun with squirrels.webp";
import banner59 from "../../../Assets/Banner/tomato crush.webp";
import banner58 from "../../../Assets/Banner/maze lover.webp";
import banner57 from "../../../Assets/Banner/sumo saga.webp";
import banner56 from "../../../Assets/Banner/stud rider.webp";
import banner55 from "../../../Assets/Banner/solider-combat.webp";
import banner54 from "../../../Assets/Banner/ninja action.webp";
import banner53 from "../../../Assets/Banner/airplane battle.webp";
import banner52 from "../../../Assets/Banner/ninja action 2.webp";
import banner51 from "../../../Assets/Banner/the office guy.webp";
import banner50 from "../../../Assets/Banner/speedy boat.webp";
import banner49 from "../../../Assets/Banner/light rays.webp";
import banner48 from "../../../Assets/Banner/egypt stone war.webp";
import banner47 from "../../../Assets/Banner/robbers in town.webp";
import banner46 from "../../../Assets/Banner/rolling panda.webp";
import banner45 from "../../../Assets/Banner/santa on skates.webp";
import banner44 from "../../../Assets/Banner/poop it.webp";
import banner43 from "../../../Assets/Banner/knight-diamond.webp";
import banner42 from "../../../Assets/Banner/dino jump.webp";
import banner41 from "../../../Assets/Banner/flying turtle.webp";
import banner40 from "../../../Assets/Banner/cupid heart.webp";
import banner39 from "../../../Assets/Banner/forest warrior.webp";
import banner38 from "../../../Assets/Banner/mr.potato.webp";
import banner37 from "../../../Assets/Banner/road fight.webp";
import banner36 from "../../../Assets/Banner/bounce ball.webp";
import banner35 from "../../../Assets/Banner/monsters up.webp";
import banner34 from "../../../Assets/Banner/jelly jump-fs8.webp";
import banner33 from "../../../Assets/Banner/goblins vs skeleton.webp";
import banner32 from "../../../Assets/Banner/jungle war.webp";
import banner31 from "../../../Assets/Banner/swing robber.webp";
import banner30 from "../../../Assets/Banner/rise up.webp";
import banner29 from "../../../Assets/Banner/mini jump.webp";
import banner28 from "../../../Assets/Banner/lizard rocket.webp";
import banner27 from "../../../Assets/Banner/captain war monster rage.webp";
import banner26 from "../../../Assets/Banner/sea ship racing.webp";
import banner25 from "../../../Assets/Banner/incredible ninja.webp";
import banner24 from "../../../Assets/Banner/chase racing cars.webp";
import banner23 from "../../../Assets/Banner/mushroom fall.webp";
import banner22 from "../../../Assets/Banner/jungle slump.webp";
import banner21 from "../../../Assets/Banner/cowboy shoot zombies.webp";
import banner20 from "../../../Assets/Banner/ogre defence.webp";
import banner19 from "../../../Assets/Banner/tom skate.webp";
import banner18 from "../../../Assets/Banner/shoot angry zombies.webp";
import banner17 from "../../../Assets/Banner/school boy warrior-fs8.webp";
import banner16 from "../../../Assets/Banner/monkey banana jump.webp";
import banner15 from "../../../Assets/Banner/capatin war zombie killer.webp";
import banner14 from "../../../Assets/Banner/fall jump roll.webp";
import banner13 from "../../../Assets/Banner/double stickman jump.webp";
import banner12 from "../../../Assets/Banner/bazooka gun  boy.webp";
import banner11 from "../../../Assets/Banner/jumping angry ape.webp";
import banner10 from "../../../Assets/Banner/cute monster.webp";
import banner9 from "../../../Assets/Banner/barrel jump.webp";
import banner8 from "../../../Assets/Banner/car speed booster.webp";
import banner7 from "../../../Assets/Banner/angry dragons.webp";
import banner6 from "../../../Assets/Banner/defenders mission.webp";
import banner5 from "../../../Assets/Banner/fighting aircraft battle.webp";
import banner4 from "../../../Assets/Banner/alien galaxy war.webp";
import banner3 from "../../../Assets/Banner/flying santa gifts.webp";
import banner2 from "../../../Assets/Banner/break the rock.webp";
import banner1 from "../../../Assets/Banner/golf park.webp";


const allGames = [
  {
    key: "97", // ''
    gameName: "QUIZ X",
    gameImg: banner97,
    gameLink: "https://gameskite.com/play/quiz-trivia-puzzle-online-game?id=portal",
    gameType: "Puzzle",
  },
  {
    key: "96", // ''
    gameName: "Sudoku X",
    gameImg: banner96,
    gameLink: "https://gameskite.com/play/sudoku-x-puzzle-game?id=portal",
    gameType: "Puzzle",
  },
  {
    key: "95", // ''
    gameName: "Onu Live",
    gameImg: banner95,
    gameLink: "https://gameskite.com/play/onu-live-cards-multiplayer?id=portal",
    gameType: "Puzzle",
  },
  {
    key: "94", // 'Carrom Live'
    gameName: "Carrom Live",
    gameImg: banner94,
    gameLink: "https://gameskite.com/play/carrom-live-multiplayer?id=portal",
    gameType: "Sports",
  },
  {
    key: "93", // 'Football Stars'
    gameName: "Football Stars",
    gameImg: banner93,
    gameLink: "https://gameskite.com/play/football-stars?id=portal",
    gameType: "Sports",
  },
  {
    key: "92", // 'captain-gold.webp'
    gameName: "Guardians Of Gold",
    gameImg: banner92,
    gameLink: "https://gameskite.com/play/guardians-of-gold?id=portal",
    gameType: "Puzzle",
  },
  {
    key: "91", // 'captain-gold.webp'
    gameName: "Captain Gold",
    gameImg: banner91,
    gameLink: "https://gameskite.com/play/captain-gold?id=portal",
    gameType: "Casual",
  },
  {
    key: "90", // 'The Dandy.webp'
    gameName: "The Dandy",
    gameImg: banner90,
    gameLink: "https://gameskite.com/play/the-dandy?id=portal",
    gameType: "Casual",
  },
  {
    key: "89", // 'Cards 21.webp'
    gameName: "Cards 21",
    gameImg: banner89,
    gameLink: "https://gameskite.com/play/cards-21?id=portal",
    gameType: "Puzzle",
  },
  {
    key: "88", // 'Fruit Chef.webp'
    gameName: "Fruit Chef",
    gameImg: banner88,
    gameLink: "https://gameskite.com/play/fruit-chef?id=portal",
    gameType: "Arcade",
  },
  {
    key: "87", // 'Cricket-live - PVP.webp'
    gameName: "Cricket Live",
    gameImg: banner87,
    gameLink: "https://gameskite.com/play/cricket-live?id=portal",
    gameType: "Sports",
  },
  {
    key: "86", // 'Droid O.webp'
    gameName: "Droid O",
    gameImg: banner86,
    gameLink: "https://gameskite.com/play/droid-o-online?id=portal",
    gameType: "Action",
  },
  {
    key: "85", // 'Robo Clone.webp'
    gameName: "Robo Clone",
    gameImg: banner85,
    gameLink: "https://gameskite.com/play/robo-clone-online?id=portal",
    gameType: "Arcade",
  },
  {
    key: "84", // 'Ram The Yoddha.webp'
    gameName: "Ram The Yoddha",
    gameImg: banner84,
    gameLink: "https://gameskite.com/play/ram-the-yoddha-online?id=portal",
    gameType: "Action",
  },
  {
    key: "83", // 'Birds vs Blocks.webp'
    gameName: "Birds vs Blocks",
    gameImg: banner83,
    gameLink: "https://gameskite.com/play/birds-vs-blocks-online?id=portal",
    gameType: "Casual",
  },
  {
    key: "82", // 'Basket Champ.webp'
    gameName: "Basket Champ",
    gameImg: banner82,
    gameLink: "https://gameskite.com/play/basket-champ-online?id=portal",
    gameType: "Sports",
  },
  {
    key: "81", // 'Aqua Blocks.webp'
    gameName: "Aqua Blocks",
    gameImg: banner81,
    gameLink: "https://gameskite.com/play/aqua-blocks-online?id=portal",
    gameType: "Puzzle",
  },
  {
    key: "80", // 'CPL Tournament.webp'
    gameName: "CPL Tournament",
    gameImg: banner80,
    gameLink: "https://gameskite.com/play/cpl-tournament-online?id=portal",
    gameType: "Sports",
  },
  {
    key: "79", // 'Krishna Jump.webp'
    gameName: "Krishna Jump",
    gameImg: banner79,
    gameLink: "https://gameskite.com/play/krishna-jump-online?id=portal",
    gameType: "Casual",
  },
  {
    key: "78", // 'Jungle bricks.webp'
    gameName: "Jungle bricks",
    gameImg: banner78,
    gameLink: "https://gameskite.com/play/jungle-bricks-online?id=portal",
    gameType: "Casual",
  },
  {
    key: "77", // 'Cricket 2022.webp'
    gameName: "Cricket 2024",
    gameImg: banner77,
    gameLink: "https://gameskite.com/play/cricket-2020-online?id=portal",
    gameType: "Sports",
  },
  {
    key: "76", // 'Street Cricket.webp'
    gameName: "Street Cricket",
    gameImg: banner76,
    gameLink: "https://gameskite.com/play/street-cricket-online?id=portal",
    gameType: "Sports",
  },
  {
    key: "75", // 'Thank you Santa.webp'
    gameName: "Thank you Santa",
    gameImg: banner75,
    gameLink: "https://gameskite.com/play/thank-you-santa-online?id=portal",
    gameType: "Casual",
  },

  {
    key: "74", // 'Halloween Night.webp'
    gameName: "Halloween Night",
    gameImg: banner74,
    gameLink: "https://gameskite.com/play/halloween-night-online?id=portal",
    gameType: "Casual",
  },
  {
    key: "73", // 'Feed Bobo.webp'
    gameName: "Feed Bobo",
    gameImg: banner73,
    gameLink: "https://gameskite.com/play/feed-bobo-online?id=portal",
    gameType: "Casual",
    gameType: "Arcade",
  },
  {
    key: "72", // 'Cricket World Cup.webp'
    gameName: "Cricket World Cup",
    gameImg: banner72,
    gameLink: "https://gameskite.com/play/cricket-world-cup-online?id=portal",
    gameType: "Sports",
  },
  {
    key: "71", // 'Guns & Bottles.webp'
    gameName: "Guns & Bottles",
    gameImg: banner71,
    gameLink: "https://gameskite.com/play/guns-&-bottles-online?id=portal",
    gameType: "Action",
  },
  {
    key: "70", // 'Run Panda Run.webp'
    gameName: "Run Panda Run",
    gameImg: banner70,
    gameLink: "https://gameskite.com/play/run-panda-run-online?id=portal",
    gameType: "Casual",
  },
  {
    key: "69", // 'Piggy Night.webp'
    gameName: "Piggy Night",
    gameImg: banner69,
    gameLink: "https://gameskite.com/play/piggy-night-online?id=portal",
    gameType: "Casual",
  },
  {
    key: "68", // 'Pingu and Friends.webp'
    gameName: "Pingu and Friends",
    gameImg: banner68,
    gameLink: "https://gameskite.com/play/pingu-and-friends-online?id=portal",
    gameType: "Casual",
  },
  {
    key: "67", // 'Santa Or Thief.webp'
    gameName: "Santa Or Thief",
    gameImg: banner67,
    gameLink: "https://gameskite.com/play/santa-or-thief-online?id=portal",
    gameType: "Arcade",
  },
  {
    key: "66", // 'Santa Ski.webp'
    gameName: "Santa Ski",
    gameImg: banner66,
    gameLink: "https://gameskite.com/play/santa-ski-online?id=portal",
    gameType: "Arcade",
  },
  {
    key: "65", // 'Crazy Balls.webp'
    gameName: "Crazy Balls",
    gameImg: banner65,
    gameLink: "https://gameskite.com/play/crazy-balls-online?id=portal",
    gameType: "Casual",
  },
  {
    key: "64", // 'Hit The Glow.webp'
    gameName: "Hit The Glow",
    gameImg: banner64,
    gameLink: "https://gameskite.com/play/hit-the-glow-online?id=portal",
    gameType: "Casual",
    gameType: "Arcade",
  },
  {
    key: "63", // 'Jumpy Kangaroo.webp'
    gameName: "Jumpy Kangaroo",
    gameImg: banner63,
    gameLink: "https://gameskite.com/play/jumpy-kangaroo-online?id=portal",
    gameType: "Casual",
  },
  {
    key: "62", // 'Halloween Bingo.webp'
    gameName: "Halloween Bingo",
    gameImg: banner62,
    gameLink: "https://gameskite.com/play/halloween-bingo-online?id=portal",
    gameType: "Puzzle",
  },
  {
    key: "61", // 'Caveman Adventure.webp'
    gameName: "Caveman Adventure",
    gameImg: banner61,
    gameLink: "https://gameskite.com/play/caveman-adventures-online?id=portal",
    gameType: "Casual",
  },
  {
    key: "60", // 'Squirrel Hop.webp'
    gameName: "Squirrel Hop",
    gameImg: banner60,
    gameLink: "https://gameskite.com/play/squirrel-hop-online?id=portal",
    gameType: "Casual",
  },
  {
    key: "59", // 'Tomato Crush.webp'
    gameName: "Tomato Crush",
    gameImg: banner59,
    gameLink: "https://gameskite.com/play/tomato-crush-online?id=portal",
    gameType: "Casual",
  },
  {
    key: "58", // 'Maze Lover.webp'
    gameName: "Maze Lover",
    gameImg: banner58,
    gameLink: "https://gameskite.com/play/maze-lover-online?id=portal",
    gameType: "Puzzle",
  },
  {
    key: "57", // 'Sumo Saga.webp'
    gameName: "Sumo Saga",
    gameImg: banner57,
    gameLink: "https://gameskite.com/play/sumo-saga-online?id=portal",
    gameType: "Casual",
  },
  {
    key: "56", // 'Stud Rider.webp'
    gameName: "Stud Rider",
    gameImg: banner56,
    gameLink: "https://gameskite.com/play/stud-rider-online?id=portal",
    gameType: "Racing",
  },
  {
    key: "55", // 'Soldier Combat.webp'
    gameName: "Soldier Combat",
    gameImg: banner55,
    gameLink: "https://gameskite.com/play/soldier-combat-online?id=portal",
    gameType: "Action",
  },
  {
    key: "54", // 'Ninja Action.webp'
    gameName: "Ninja Action",
    gameImg: banner54,
    gameLink: "https://gameskite.com/play/ninja-action-online?id=portal",
    gameType: "Casual",
  },
  // {
  //   key: "541", // 'Village Story.webp'
  //   gameName: "Village Story",
  //   gameImg: banner541,
  //   gameLink: "https://gameskite.com/play/me/village-story-new1?id=portal",
  // },
  {
    key: "53", // 'Airplane Battle.webp'
    gameName: "Airplane Battle",
    gameImg: banner53,
    gameLink: "https://gameskite.com/play/airplane-battle-online?id=portal",
    gameType: "Action",
  },
  {
    key: "52", // 'Ninja Action 2.webp'
    gameName: "Ninja Action 2",
    gameImg: banner52,
    gameLink: "https://gameskite.com/play/ninja-action-2-online?id=portal",
    gameType: "Casual",
  },
  {
    key: "51", // 'The Office Guy.webp'
    gameName: "The Office Guy",
    gameImg: banner51,
    gameLink: "https://gameskite.com/play/the-office-guy-online?id=portal",
    gameType: "Action",
  },
  {
    key: "50", // 'Speedy Boat.webp'
    gameName: "Speedy Boat",
    gameImg: banner50,
    gameLink: "https://gameskite.com/play/speedy-boat-online?id=portal",
    gameType: "Racing",
  },
  {
    key: "49", // 'Light Rays.webp'
    gameName: "Light Rays",
    gameImg: banner49,
    gameLink: "https://gameskite.com/play/light-rays-online?id=portal",
    gameType: "Puzzle",
  },
  {
    key: "48", // 'Egypt Stone War.webp'
    gameName: "Egypt Stone War",
    gameImg: banner48,
    gameLink: "?https://gameskite.com/play/egypt-stone-war-online?id=portal",
    gameType: "Arcade",
  },
  {
    key: "47", // 'Robbers in Town.webp'
    gameName: "Robbers in Town",
    gameImg: banner47,
    gameLink: "https://gameskite.com/play/robbers-in-town-online?id=portal",
    gameType: "Arcade",
  },
  {
    key: "46", // 'Rolling Panda.webp'
    gameName: "Rolling Panda",
    gameImg: banner46,
    gameLink: "https://gameskite.com/play/rolling-panda-online?id=portal",
    gameType: "Casual",
  },
  {
    key: "45", // 'Santa on Skates.webp'
    gameName: "Santa on Skates",
    gameImg: banner45,
    gameLink: "https://gameskite.com/play/santa-on-skates-online?id=portal",
    gameType: "Arcade",
  },
  {
    key: "44", // 'Poop It.webp'
    gameName: "Poop It",
    gameImg: banner44,
    gameLink: "https://gameskite.com/play/poop-it-online?id=portal",
    gameType: "Casual",
  },
  {
    key: "43", // 'Knights Diamond.webp'
    gameName: "Knights Diamond",
    gameImg: banner43,
    gameLink: "https://gameskite.com/play/knights-diamond-online?id=portal",
    gameType: "Action",
  },
  {
    key: "42", // 'Dino Jump.webp'
    gameName: "Dino Jump",
    gameImg: banner42,
    gameLink: "https://gameskite.com/play/dino-jump-online?id=portal",
    gameType: "Casual",
    gameType: "Arcade",
  },
  {
    key: "41", // 'Flying Turtle.webp'
    gameName: "Flying Turtle",
    gameImg: banner41,
    gameLink: "https://gameskite.com/play/flying-turtle-online?id=portal",
    gameType: "Casual",
  },
  {
    key: "40", // 'Cupid Heart.webp'
    gameName: "Cupid Heart",
    gameImg: banner40,
    gameLink: "https://gameskite.com/play/cupid-heart-online?id=portal",
    gameType: "Arcade",
  },
  {
    key: "39", // 'Forest Warrior.webp'
    gameName: "Forest Warrior",
    gameImg: banner39,
    gameLink: "https://gameskite.com/play/forest-warrior-online?id=portal",
    gameType: "Action",
  },
  {
    key: "38", // 'Mr. Potato.webp'
    gameName: "Mr. Potato",
    gameImg: banner38,
    gameLink: "https://gameskite.com/play/mr-potato-online?id=portal",
    gameType: "Casual",
  },
  {
    key: "37", // 'Road Fight.webp'
    gameName: "Road Fight",
    gameImg: banner37,
    gameLink: "https://gameskite.com/play/road-fight-online?id=portal",
    gameType: "Racing",
  },
  {
    key: "36", // 'Bounce Ball.webp'
    gameName: "Bounce Ball",
    gameImg: banner36,
    gameLink: "https://gameskite.com/play/bounce-ball-online?id=portal",
    gameType: "Arcade",
  },
  {
    key: "35", // 'Monsters Up.webp'
    gameName: "Monsters Up",
    gameImg: banner35,
    gameLink: "https://gameskite.com/play/monsters-up-online?id=portal",
    gameType: "Casual",
  },
  {
    key: "34", // 'Jelly Jump.webp'
    gameName: "Jelly Jump",
    gameImg: banner34,
    gameLink: "https://gameskite.com/play/jelly-jump-online?id=portal",
    gameType: "Casual",
  },
  {
    key: "33", // 'Goblin Vs Skeleton.webp'
    gameName: "Goblin Vs Skeleton",
    gameImg: banner33,
    gameLink: "https://gameskite.com/play/goblin-vs-skeleton-online?id=portal",
    gameType: "Casual",
  },
  {
    key: "32", // 'Jungle War.webp'
    gameName: "Jungle War",
    gameImg: banner32,
    gameLink: "https://gameskite.com/play/jungle-war-online?id=portal",
    gameType: "Action",
  },
  {
    key: "31", // 'Swing Robber.webp'
    gameName: "Swing Robber",
    gameImg: banner31,
    gameLink: "https://gameskite.com/play/swing-robber-online?id=portal",
    gameType: "Casual",
  },
  {
    key: "30", // 'Rise Up.webp'
    gameName: "Rise Up",
    gameImg: banner30,
    gameLink: "https://gameskite.com/play/rise-up-online?id=portal",
    gameType: "Casual",
  },
  {
    key: "29", // 'Mini Jump.webp'
    gameName: "Mini Jump",
    gameImg: banner29,
    gameLink: "https://gameskite.com/play/mini-jump-online?id=portal",
    gameType: "Casual",
  },
  {
    key: "28", // 'Lizard Rocket.webp'
    gameName: "Lizard Rocket",
    gameImg: banner28,
    gameLink: "https://gameskite.com/play/lizard-rocket-online?id=portal",
    gameType: "Casual",
  },
  {
    key: "27", // 'Captain War Monster Rage.webp'    
    gameName: "Captain War Monster",
    gameImg: banner27,
    gameLink: "https://gameskite.com/play/captain-war-monster-rage-online?id=portal",
    gameType: "Action",
  },
  {
    key: "26", // 'Sea Ship Racing.webp'
    gameName: "Sea Ship Racing",
    gameImg: banner26,
    gameLink: "https://gameskite.com/play/sea-ship-racing-online?id=portal",
    gameType: "Racing",
  },
  {
    key: "25", // 'Incredible Ninja.webp'
    gameName: "Incredible Ninja",
    gameImg: banner25,
    gameLink: "https://gameskite.com/play/incredible-ninja-online?id=portal",
    gameType: "Casual",
  },
  {
    key: "24", // 'Chase Racing car.webp'
    gameName: "Chase Racing car",
    gameImg: banner24,
    gameLink: "https://gameskite.com/play/chase-racing-cars-online?id=portal",
    gameType: "Racing",
  },
  {
    key: "23", // 'Mushroom Fall.webp'
    gameName: "Mushroom Fall",
    gameImg: banner23,
    gameLink: "https://gameskite.com/play/mushroom-fall-online?id=portal",
    gameType: "Arcade",
  },
  {
    key: "22", // 'Jungle Slump.webp'
    gameName: "Jungle Slump",
    gameImg: banner22,
    gameLink: "https://gameskite.com/play/jungle-slump-online?id=portal",
    gameType: "Arcade",
  },
  {
    key: "21", // 'CowBoy Shoot Zombies.webp'
    gameName: "CowBoy Shoot",
    gameImg: banner21,
    gameLink: "https://gameskite.com/play/cowboy-shoot-zombies-online?id=portal",
    gameType: "Action",
  },
  {
    key: "20", // 'Ogre Defense.webp'
    gameName: "Ogre Defense",
    gameImg: banner20,
    gameLink: "https://gameskite.com/play/ogre-defense-online?id=portal",
    gameType: "Action",
  },
  {
    key: "19", // 'Tom Skate.webp'
    gameName: "Tom Skate",
    gameImg: banner19,
    gameLink: "https://gameskite.com/play/tom-skate-online?id=portal",
    gameType: "Arcade",
  },
  {
    key: "18", // 'Shoot Angry Zombies.webp'
    gameName: "Angry Zombies",
    gameImg: banner18,
    gameLink: "https://gameskite.com/play/shoot-angry-zombies-online?id=portal",
    gameType: "Action",
  },
  {
    key: "17", // 'School Boy Warrior.webp'
    gameName: "School Boy Warrior",
    gameImg: banner17,
    gameLink: "https://gameskite.com/play/school-boy-warrior-online?id=portal",
    gameType: "Action",
  },
  {
    key: "16", // 'Monkey Banana Jump.webp'
    gameName: "Monkey Banana Jump",
    gameImg: banner16,
    gameLink: "https://gameskite.com/play/monkey-banana-jump-online?id=portal",
    gameType: "Casual",
  },
  {
    key: "15", // 'Captain War Zombie Killer.webp'   
    gameName: "Captain War",
    gameImg: banner15,
    gameLink: "https://gameskite.com/play/captain-war-zombie-killer-online?id=portal",
    gameType: "Action",
  },
  {
    key: "14", // 'Fall Jump Roll.webp'
    gameName: "Fall Jump Roll",
    gameImg: banner14,
    gameLink: "https://gameskite.com/play/fall-jump-roll-online?id=portal",
    gameType: "Casual",
  },
  {
    key: "13", // 'Double Stickman.webp'
    gameName: "Double Stickman",
    gameImg: banner13,
    gameLink: "https://gameskite.com/play/double-stickman-online?id=portal",
    gameType: "Casual",
  },
  {
    key: "12", // 'Bazooka Gun.webp'
    gameName: "Bazooka Gun",
    gameImg: banner12,
    gameLink: "https://gameskite.com/play/bazooka-gun-boy-online?id=portal",
    gameType: "Action",
  },
  {
    key: "11", // 'Jumping Angry Ape.webp'
    gameName: "Jumping Angry Ape",
    gameImg: banner11,
    gameLink: "https://gameskite.com/play/jumping-angry-ape-online?id=portal",
    gameType: "Casual",
  },
  {
    key: "10", // 'Cute Monster Jump.webp'
    gameName: "Cute Monster Jump",
    gameImg: banner10,
    gameLink: "https://gameskite.com/play/cute-monster-jump-online?id=portal",
    gameType: "Casual",
  },
  {
    key: "9", // 'Barrel Jump.webp'
    gameName: "Barrel Jump",
    gameImg: banner9,
    gameLink: "https://gameskite.com/play/barrel-jump-online?id=portal",
    gameType: "Arcade",
  },
  {
    key: "8", // 'Car Speed Booster.webp'
    gameName: "Car Speed Booster",
    gameImg: banner8,
    gameLink: "https://gameskite.com/play/car-speed-booster-online?id=portal",
    gameType: "Racing",
  },
  {
    key: "7", // 'Angry Dragon.webp'
    gameName: "Angry Dragon",
    gameImg: banner7,
    gameLink: "https://gameskite.com/play/angry-dragon-online?id=portal",
    gameType: "Action",
  },
  {
    key: "6", // 'Defender Mission.webp'
    gameName: "Defender Mission",
    gameImg: banner6,
    gameLink: "https://gameskite.com/play/defender-mission-online?id=portal",
    gameType: "Action",
  },
  {
    key: "5", // 'Fighting Aircraft Battle.webp'
    gameName: "Fighting Aircraft",
    gameImg: banner5,
    gameLink: "https://gameskite.com/play/fighting-aircraft-battle-online?id=portal",
    gameType: "Action",
  },
  {
    key: "4", // 'Alien Galaxy War.webp'
    gameName: "Alien Galaxy War",
    gameImg: banner4,
    gameLink: "https://gameskite.com/play/alien-galaxy-war-online?id=portal",
    gameType: "Action",
  },
  {
    key: "3", // 'Flying Santa Gifts.webp'
    gameName: "Flying Santa Gifts",
    gameImg: banner3,
    gameLink: "https://gameskite.com/play/flying-santa-gifts-online?id=portal",
    gameType: "Arcade",
  },
  {
    key: "2", // 'Break The Rock.webp'
    gameName: "Break The Rock",
    gameImg: banner2,
    gameLink: "https://gameskite.com/play/break-the-rock-online?id=portal",
    gameType: "Casual",
  },
  {
    key: "1", // 'Golf Park.webp'
    gameName: "Golf Park",
    gameImg: banner1,
    gameLink: "https://gameskite.com/play/golf-park-online?id=portal",
    gameType: "Sports",
  },
];


const PageTwo = () => {
  const navigate = useNavigate();
  const [active, setActive] = useState(false);
  const [activeIndex, setActiveIndex] = useState(false);
  const [activeIndexPosition, setActiveIndexPosition] = useState();
  const [selectCategory, setSelectCategory] = useState(false);
  const [gameDataHead, setGameDataHead] = useState([]);
  const [gameDataBody, setGameDataBody] = useState([]);
  const [gameDataBody2, setGameDataBody2] = useState([]);
  const [showMore, setShowMore] = useState(false);
  // const [selectedGameType, setSelectedGameType] = useState("All");
  const [gameType, setGameType] = useState("All");

  const handleButtonClick = (type) => {
    setGameType(type);
    filterGames(type);
  };

  const filterGames = (type) => {
    if (type === "All") {
      setGameDataHead(allGames.slice(0, 3));
      setGameDataBody(allGames.slice(3, 19));
      setGameDataBody2(allGames.slice(19));
    } else {
      const filteredGames = allGames.filter((game) => game.gameType === type);
      setGameDataHead(filteredGames.slice(0, 3));
      setGameDataBody(filteredGames.slice(3, 19));
      setGameDataBody2(filteredGames.slice(19));
    }
    setShowMore(false);
  };
  useEffect(() => {
    handleButtonClick("All");
  }, []);

  return (
    <div className="pPageTwo_Background" style={{}}>
      <Row justify="center">
        <Col className="pf_page_two_ourgames">CASUAL GAMES</Col>
      </Row>
      <div
        style={{
          padding: "0 15px",
          justifyContent: "center",
          align_items: "center",
          display: "flex",
          marginTop: 5,
        }}
      >
        <div className="pf_pageTwo_hBar">
          <div className="pf_page_two_hEl" />
        </div>
      </div>


      <div className="button-group button-group_mobile">
        <Button
          onClick={() => handleButtonClick("All")}
          className={gameType === "All" ? "selected btn btn-primary" : "btn btn-primary"}
        >
          ALL
        </Button>
        <Button
          onClick={() => handleButtonClick("Casual")}
          className={gameType === "Casual" ? "selected btn btn-primary" : "btn btn-primary"}
        >
          CASUAL
        </Button>
        <Button
          onClick={() => handleButtonClick("Puzzle")}
          className={gameType === "Puzzle" ? "selected btn btn-primary" : "btn btn-primary"}
        >
          PUZZLE
        </Button>
        <Button
          onClick={() => handleButtonClick("Action")}
          className={gameType === "Action" ? "selected btn btn-primary" : "btn btn-primary"}
        >
          ACTION
        </Button>
        <Button
          onClick={() => handleButtonClick("Arcade")}
          className={gameType === "Arcade" ? "selected btn btn-primary1" : "btn btn-primary1"}
        >
          ARCADE
        </Button>
        <Button
          onClick={() => handleButtonClick("Sports")}
          className={gameType === "Sports" ? "selected btn btn-primary2" : "btn btn-primary2"}
        >
          SPORTS
        </Button>
        <Button
          onClick={() => handleButtonClick("Racing")}
          className={gameType === "Racing" ? "selected btn btn-primary3" : "btn btn-primary3"}
        >
          RACING
        </Button>

      </div>


      <div className="pageTwo_AllGamesCard">
        <div className="pageTwo_HeadCardDesktop">
          {gameDataHead.map((game, index) => (
            <div key={index}>
              <a href={game.gameLink} target="_blank">
                <div className="pageTwo_BodyCardChild setHeaderFont">
                  <img
                    className="bodyCardImage"
                    src={game.gameImg}
                    alt="Geeks Img"
                    width={360}
                  />
                  <div className="pageTwo_GameTitle">
                    {String(game.gameName).toUpperCase()}
                  </div>
                </div>
              </a>
            </div>
          ))}
        </div>
        <div className="pageTwo_HeadCardMobile">
          {gameDataHead &&
            gameDataHead.map((game, index) => {
              return (
                <div key={index}>
                  <a href={game.gameLink} target="_blan">
                    <div className="pageTwo_BodyCardChild">
                      <img
                        className="bodyCardImage"
                        src={game.gameImg}
                        alt=""
                        width={260}
                      // height={"100%"}
                      />
                      <div className="pageTwo_GameTitle">{String(game.gameName).toUpperCase()}</div>
                    </div>
                  </a>
                </div>
              );
            })}
        </div>
        <div className="pageTwo_BodyCard">
          {gameDataBody &&
            gameDataBody.map((game, index) => {
              return (
                <div key={index}>
                  <a href={game.gameLink} target="_blan">
                    <div className="pageTwo_BodyCardChild setBodyFont">
                      <img
                        className="bodyCardImage"
                        src={game.gameImg}
                        alt=""
                        width={260}
                        height={"100%"}
                      />
                      <div className="pageTwo_GameTitle">{String(game.gameName).toUpperCase()}</div>
                    </div>
                  </a>
                </div>
              );
            })}
          {
            showMore === true
            &&
            gameDataBody2 &&
            gameDataBody2.map((game, index) => {
              return (
                <div key={index}>
                  <a href={game.gameLink} target="_blan">
                    <div className="pageTwo_BodyCardChild">
                      <img
                        className="bodyCardImage"
                        src={game.gameImg}
                        alt=""
                        width={260}
                      // height={"100%"}
                      />
                      <div className="pageTwo_GameTitle">{String(game.gameName).toUpperCase()}</div>
                    </div>
                  </a>
                </div>
              );
            })}
        </div>
      </div>

      <div
        style={{
          display: "flex",
          justifyContent: "center",
          // margin: "10px 15px 15px 15px",
        }}
      >
        <div
          className="pf_pageTwo_explore_more"
          onClick={() => {
            if (showMore) {
              setShowMore(false);
              window.scrollTo(0, 0); // Scroll to top when loading less
            } else {
              setShowMore(true);
              window.scrollTo(0, 1300);
            }
          }}
          style={{ display: gameDataBody2.length > 0 ? "block" : "none" }}
        >
          {showMore === true ? "LOAD LESS" : "LOAD MORE"}
        </div>

      </div>
    </div>
  );
};

export default PageTwo;
